const Varieties = () =>
  import(
    /* webpackChunkName: "planning-module" */ '@/pages/cultivares/VarietyHome.vue'
  )
const Programs = () =>
  import(
    /* webpackChunkName: "planning-module" */ '@/pages/programas/DiseaseHome.vue'
  )
const SowingsPlanningHome = () =>
  import(
    /* webpackChunkName: "planning-module" */ '@/modules/sowingsPlanning/views/SowingsPlanningHome.vue'
  )

const routes = [
  {
    name: 'varieties',
    path: '/cultivares',
    component: Varieties,
    meta: {
      requiresAuth: true,
    },
  },
  // CM: Esse cara vai substituir o "varieties"
  {
    name: 'sowings_planning',
    path: '/sowings_planning',
    component: SowingsPlanningHome,
    meta: {
      requiresAuth: true,
    },
  },
  {
    name: 'programs',
    path: '/programas',
    component: Programs,
    meta: {
      requiresAuth: true,
    },
  },
]

export default routes
